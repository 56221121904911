import React, { FunctionComponent, useContext, useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import sanityImageUrlBuilder from "web-common/src/utils/imageUrlBuilder";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import { LocalizedContext } from "web-common/src/services/LocalizedContextService";
import { VideoPause } from "../../images/icons/videoPause";
import { VideoPlay } from "../../images/icons/videoPlay";
import Accessibility from "../Accessibility";
import "./styles.scss";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

// TODOs:
// Video support for background / foreground videos (with play button)
// If foreground video 
//  support for Poster Image
//  Autoplay false
//  Play Icon in center

const FeaturedVideo: FunctionComponent = (props) => {
  const [videoButtonlabel, setVideoButtonlabel] = useState("Pause");
  const [isPlaying, setIsPlaying] = useState(true);

  const videoBtnRefs = useRef([]);
  const videoRefs = useRef([]);

  let { headline, video, videoAltText, ctaReference, ctaLabel, index, _rawIntroduction } = props;

  const handleVideoPlayPause = (ev, idx) => {
    if (videoRefs.current[idx].paused) {
      videoRefs.current[idx].play();
      setIsPlaying(true);
      setVideoButtonlabel(videoBtnRefs.current[idx].dataset.pause);
    } else {
      videoRefs.current[idx].pause();
      setIsPlaying(false);
      setVideoButtonlabel(videoBtnRefs.current[idx].dataset.play);
    }
  };

  useEffect(() => {
    setIsPlaying(true);
  }, []);

  const Heading = ({ headingLevel, children }) => {
    const Heading = headingLevel;
    return <Heading className="featured-video--title">{children}</Heading>
  }

  return (
    <section className={`featured-video`}>
      <div className="featured-video--image">
        <video
          autoPlay={true}
          ref={el => (videoRefs.current[index] = el)}
          src={video.asset.url}
          playsInline
          muted
          loop
          preload="metadata"
          type="video/mp4"
          title={headline.primaryText || headline.secondaryText || headline.tertiaryText}
        >
          <p>
            Your browser doesn't support HTML5 video. Here is a{" "}
            <a href={video.asset.url}>link to the video</a> instead.
          </p>
          {videoAltText?.asset && <track src={videoAltText.asset.url} kind="captions" />}
        </video>
        <button
          ref={el => (videoBtnRefs.current[index] = el)}
          data-play="Play"
          data-pause="Pause"
          onClick={ev => handleVideoPlayPause(ev, index)}
          className={"btn-playPause"}
        >
          {isPlaying ? <VideoPause /> : <VideoPlay />}
          <Accessibility location={videoButtonlabel} />
        </button>
      </div>
      <div className="featured-video--copy">
        <Container fluid>
          <div className="row">
            <div className="col-xs-12">
              <div
                className={`feaured-video--wrapper`}
              >
                {headline.primaryText && <p className="featured-video--subtitle">{headline.primaryText}</p>}
                <Heading headingLevel={props.index === 0 ? "h1" : "h2"}>
                  {headline.secondaryText && <span>{headline.secondaryText}</span>}
                  {headline.tertiaryText && <span>{headline.tertiaryText}</span>}
                </Heading>
                {_rawIntroduction && <div>
                  <BlockContent blocks={_rawIntroduction} />
                </div>
                }
              </div>
              {(ctaLabel && ctaReference) && <Link
                className={`btn-primary`}
                to={`${ctaReference?.slug.current}`}
                id={"fearturedVideoCallToAction"}
              >
                {ctaLabel}
              </Link>}
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};
export default FeaturedVideo;
